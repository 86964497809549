//state
const state = {
  // 用户信息
  userInfo: {
    userid: "",
    username: "",
  },
  // 广告位置
  adSlots: [1, 2, 34, 4, 5],
};

// getters
const getters = {
  getState(state) {
    return {
      userInfo: state.userInfo,
      adSlots: state.adSlots,
    };
  },
};

// actions
const actions = {};

// mutations
const mutations = {
  //设置城市列表
  setAdSlots(state, adSlots) {
    state.adSlots = adSlots;
  },
  setUserInfo(state, userInfo) {
    state.userInfo = userInfo;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
